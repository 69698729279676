import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { X, Menu, Hash } from "react-feather";
import { HashLink } from "react-router-hash-link";
export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      console.log("not mobile");
      setIsOpen(true);
    }
  }, [isMobile]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isLinkActive = (path: string) => {
    const { pathname, hash } = location;

    const currentPath = pathname + hash;

    return currentPath === path;
  };

  const activeStyle = {
    textDecoration: "underline",
  };

  const inActiveStyle = {
    // Stuffs
  };

  return (
    <nav className="fixed top-0 w-full h-fit">
      <div
        className={`flex bg-zinc-100 sm:bg-opacity-75 sm:justify-between items-center gap-10 sm:gap-0 justify-center w-full ${isOpen ? "h-screen" : "h-fit"} sm:h-24 top-0 left-0 relative z-20 flex-col sm:flex-row`}
      >
        {isOpen ? (
          <X
            onClick={() => {
              toggleMenu();
            }}
            color="black"
            height={50}
            width={50}
            className="absolute top-1 right-1 sm:hidden"
          />
        ) : (
          <Menu
            onClick={() => {
              toggleMenu();
            }}
            color="black"
            height={40}
            width={40}
            className="absolute top-2 right-2 sm:hidden"
          />
        )}
        {isOpen ? (
          <>
            <div className="flex flex-col sm:w-fit gap-10 sm:flex-row justify-center smjustify-between items-center sm:ml-8">
              <HashLink
                to="/#home"
                smooth
                onClick={
                  isMobile
                    ? toggleMenu
                    : () => {
                        return;
                      }
                }
                className="text-2xl font-medium text-black underline-offset-4 hover:underline"
                style={
                  isLinkActive("/") || isLinkActive("/#home")
                    ? activeStyle
                    : inActiveStyle
                }
              >
                Home
              </HashLink>
              <HashLink
                onClick={
                  isMobile
                    ? toggleMenu
                    : () => {
                        return;
                      }
                }
                smooth
                to="/#about"
                className="text-2xl font-medium text-black underline-offset-4 hover:underline"
                style={isLinkActive("/#about") ? activeStyle : inActiveStyle}
              >
                About
              </HashLink>
              <HashLink
                onClick={
                  isMobile
                    ? toggleMenu
                    : () => {
                        return;
                      }
                }
                to={"/#tech"}
                smooth
                className="text-2xl font-medium text-black underline-offset-4 hover:underline"
                style={isLinkActive("/#tech") ? activeStyle : inActiveStyle}
              >
                Tech
              </HashLink>

              <Link
                onClick={
                  isMobile
                    ? toggleMenu
                    : () => {
                        return;
                      }
                }
                to="/blog"
                className="text-2xl font-medium text-black underline-offset-4 hover:underline"
                style={isLinkActive("/blog") ? activeStyle : inActiveStyle}
              >
                Blog
              </Link>
            </div>
            <div className="flex sm:justify-between items-center sm:mr-8">
              <Link
                onClick={
                  isMobile
                    ? toggleMenu
                    : () => {
                        return;
                      }
                }
                to="/connection"
                className="text-2xl font-medium text-black underline-offset-4 hover:underline"
                style={
                  isLinkActive("/connection") ? activeStyle : inActiveStyle
                }
              >
                Connection Test
              </Link>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </nav>
  );
}
