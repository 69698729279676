import React, { useEffect, useState } from "react";

export default function ConnectionTest() {
  const [connected, setConnected] = useState(false);

  const retrieve = async (url: string) => {
    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.message === "pong") {
        setConnected(true);
      } else {
        setConnected(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(process.env.REACT_APP_URL);
    retrieve(`${process.env.REACT_APP_URL}/api/ping`);
  }, []);

  return (
    <div className="w-screen h-screen overflow-hidden bg-zinc-100 dm-sans flex justify-center items-center">
      <div className="">
        <h1 className="lg:text-8xl font-medium">
          The server is{" "}
          {connected ? (
            <span className="text-green-500">connected</span>
          ) : (
            <span className="text-red-500">disconnected</span>
          )}
        </h1>
      </div>
    </div>
  );
}
