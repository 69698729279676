import React from "react";

export default function ErrorPage() {
  return (
    <div className="h-screen w-screen bg-black flex items-center justify-center">
      <h1 className="text-white text-center pt-10 text-2xl md:text-4xl">
        404: Page Not Found
      </h1>
    </div>
  );
}
