import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router";
import Home from "./pages/home";
import ErrorPage from "./pages/error-page";
import ConnectionTest from "./pages/connection-test";
import Navbar from "./components/navbar";
import Projects from "./pages/projects";
import Blog from "./pages/blog";

function App() {
  useEffect(() => {
    console.log("App mounted");
  }, []);

  return (
    <div className="relative">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/connection" element={<ConnectionTest />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
