import React from "react";

export default function Blog() {
  return (
    <div className="w-screen h-screen overflow-hidden bg-zinc-100 dm-sans flex justify-center items-center">
      <div className="w-5/6">
        <h1 className="lg:text-8xl font-medium">
          My <span className="text-green-400">blog</span> will be{" "}
          <span className="italic">here</span>.
        </h1>
      </div>
    </div>
  );
}
