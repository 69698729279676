import React from "react";
import "./css/home.css";
import myImage from "../images/me.jpg";
import { useAnimate } from "framer-motion";

export default function Home() {
  const [scope, animate] = useAnimate();

  return (
    <div ref={scope} className="overflow-x-hidden">
      <div
        id="home"
        className="w-screen h-screen bg-zinc-100 dm-sans flex justify-center items-center"
      >
        <div className="sm:w-[80%] flex flex-col-reverse sm:flex-row justify-between items-center gap-10 w-2/3 sm:gap-0">
          <div className="flex flex-col gap-2 sm:gap-5 w-5/6">
            <h1 className="lg:text-8xl md:text-6xl sm:text-5xl text-4xl font-medium">
              Hi, I&apos;m{" "}
              <span className="bg-clip-text bg-gradient-to-r from-green-400 to-blue-500 text-transparent">
                Alexander Wang
              </span>
            </h1>
            <div className="flex flex-col gap-3 w-5/6">
              <p className="lg:text-4xl md:text-xl text-lg">
                Software Engineer & Student @{" "}
                <span
                  style={{
                    color: "#0021A5",
                  }}
                >
                  University
                </span>{" "}
                of{" "}
                <span
                  style={{
                    color: "#FA4616",
                  }}
                >
                  Florida
                </span>
                .
              </p>
            </div>
          </div>
          <img
            src={myImage}
            alt="Image of me"
            className="h-[35vh] lg:h-[30rem] rounded-md shadow-lg hover:scale-105 transition-all hover:shadow-2xl"
          />
        </div>
      </div>
      <div
        id="about"
        className="w-full h-[75vh] bg-zinc-100 dm-sans flex justify-center items-center"
      >
        <div className="flex flex-col sm:flex-row w-2/3 sm:justify-between gap-20">
          <h1 className="text-3xl md:text-5xl lg:text-7xl font-medium flex justify-center items-center underline underline-offset-4">
            About Me
          </h1>
          <p className="text-xl sm:text-2xl md:text-3xl w-full md:w-1/2">
            Sophomore @{" "}
            <span
              style={{
                color: "#0021A5",
              }}
            >
              UF
            </span>{" "}
            studying Computer Science. Focus in{" "}
            <span className="text-purple-500">Computer Vision 👁️</span> and{" "}
            <span className="text-green-600">Machine Learning 🧠</span>.
            <br />
            <br />
            I&apos;d love to work on{" "}
            <span className="text-red-600">robots</span> 🤖 and autonomous
            vehicles for a career.
            <br />
            <br />I also <span className="text-red-500">&lt;3</span> web/mobile
            dev!
          </p>
        </div>
      </div>
      <div
        id="tech"
        className="w-full h-[120vh] md:h-[100vh] bg-zinc-100 flex justify-center items-center"
      >
        <div className="w-[80%] flex flex-col items-center dm-sans gap-5 md:gap-14">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl underline underline-offset-4 font-medium">
            Technologies & Skills
          </h1>
          <div className="w-full flex flex-col md:flex-row justify-between gap-4 md:gap-0">
            <div
              id="card-1"
              className="md:h-[60vh] md:w-[30%] h-[30vh] shadow-2xl rounded-2xl flex flex-col bg-gradient-to-br from-green-400 to-blue-500"
            >
              <div className="px-3 py-8 h-full w-full flex flex-col items-center">
                <h1 className="text-zinc-100 text-xl md:text-2xl lg:text-4xl font-bold text-center w-full">
                  Machine Learning
                </h1>
                <div className="text-zinc-200 grow w-2/3 md:text-xl sm:text-lg text-sm lg:text-2xl font-medium justify-evenly flex flex-col">
                  <p>PyTorch</p>
                  <p>OpenCV</p>
                  <p>Tensorflow Keras</p>
                  <p>Jupyter Notebook</p>
                  <p>Google Colab</p>
                </div>
              </div>
            </div>
            <div
              id="card-1"
              className="md:h-[60vh] md:w-[30%] h-[30vh] shadow-2xl rounded-2xl flex flex-col bg-gradient-to-br from-purple-500 to-pink-500"
            >
              <div className="px-3 py-8 h-full w-full flex flex-col items-center">
                <h1 className="text-zinc-100 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold  text-center w-full">
                  Web Development
                </h1>
                <div className="w-2/3 flex flex-row grow gap-7">
                  <div className="text-zinc-200 grow w-2/3 md:text-xl sm:text-lg text-sm lg:text-2xl font-medium justify-evenly flex flex-col">
                    <p>ReactJS</p>
                    <p>ExpressJS</p>
                    <p>NodeJS</p>
                    <p>AWS EC2</p>
                    <p>React Native</p>
                  </div>
                  <div className="text-zinc-200 grow w-2/3 md:text-xl sm:text-lg text-sm lg:text-2xl font-medium justify-evenly flex flex-col">
                    <p>Socket.io</p>
                    <p>Flask</p>
                    <p>Expo</p>
                    <p>NextJS</p>
                    <p>Vercel</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="card-1"
              className="md:h-[60vh] md:w-[30%] h-[30vh] shadow-2xl rounded-2xl flex flex-col bg-gradient-to-br from-yellow-400 to-red-400"
            >
              <div className="px-3 py-8 h-full w-full flex flex-col items-center">
                <h1 className="text-zinc-100 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold  text-center w-full">
                  Embedded & Robotics
                </h1>
                <div className="text-zinc-200 grow w-2/3 md:text-xl sm:text-lg text-sm lg:text-2xl font-medium justify-evenly flex flex-col">
                  <p>C++</p>
                  <p>Rust</p>
                  <p>ROS</p>
                  <p>Arduinos & Microcontrollers</p>
                  <p>Gazebo Sim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
